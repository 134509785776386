<template>
    <div
        class="inline-flex rounded-full items-center justify-center space-x-1 transtion-colors duration-150"
        :class="[buttonClasses, buttonSizeClasses, disabledClasses, loadingClasses]"
        @click="emit('click')"
    >
        <template v-if="loading">
            <DLoader :active="loading" />
        </template>
        <template v-else>
            <UIIcon v-if="leftIcon" :icon="leftIcon" :size="iconSize" weight="regular" />
            <span class="font-medium"><slot /></span>
            <UIIcon v-if="icon" :icon="icon" :size="iconSize" weight="regular" />
        </template>
    </div>
</template>

<script setup>
    import { DLoader } from '@digistorm/spark'
    import UIIcon from '@/components/ui/UIIcon.vue'

    const emit = defineEmits(['click'])

    const props = defineProps({
        icon: Object,
        leftIcon: Object,
        type: {
            type: String,
            default: 'primary',
        },
        size: {
            type: String,
            default: 'md',
        },
        iconSize: {
            type: Number,
            default: 24,
        },
        disabled: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
        },
    })

    const buttonClasses = computed(() => {
        switch (props.type) {
            case 'secondary':
                return 'bg-primary-700/[.07] text-primary-700 active:bg-primary-700/[.14] hover:bg-primary-700/[.14]'
            case 'outline':
                return 'border border-gray-300 text-gray-800'
            case 'transparent':
                return 'text-primary-700 active:bg-primary-700/[.14] hover:bg-primary-700/[.14]'
            case 'primary':
            default:
                return 'bg-primary-700 text-white active:bg-primary-800 hover:bg-primary-800'
        }
    })

    const buttonSizeClasses = computed(() => {
        switch (props.size) {
            case 'lg':
                return 'px-[44px] w-full py-[11px] text-sm'
            case 'md':
            default:
                return 'px-5 py-2.5 text-[13px]'
        }
    })

    const disabledClasses = computed(() => {
        if (!props.disabled) {
            return ''
        }
        return 'pointer-events-none opacity-30'
    })

    const loadingClasses = computed(() => {
        if (!props.loading) {
            return ''
        }
        return 'flex items-center justify-center pointer-events-none'
    })
</script>

<template>
    <UICard :title="config.data.title" :badge="config.data.badge">
        <div class="flex">
            <div class="flex-col items-center w-6/12 justify-center text-center">
                <div :class="scoreClasses">{{ config.data.left_score }}</div>
                <div class="text-sm font-medium text-gray-800">{{ config.data.left_text }}</div>
            </div>
            <div class="max-h-[3.75rem] inline-flex items-center font-semibold text-2xl text-gray-400 dark:text-gray-600">-</div>
            <div class="flex-col items-center w-6/12 justify-center text-center">
                <div :class="scoreClasses">{{ config.data.right_score }}</div>
                <div class="text-sm font-medium text-gray-800">{{ config.data.right_text }}</div>
            </div>
        </div>
    </UICard>
</template>

<script setup>
    const props = defineProps({
        config: Object,
    })

    const moduleStore = useModuleStore()
    const moduleHelpers = useModuleHelpers()

    const scoreClasses = [
        'min-w-[3.75rem]',
        'min-h-[3.75rem]',
        'mb-3',
        'px-2',
        'text-[1.875rem]',
        'inline-flex',
        'font-semibold',
        'flex',
        'items-center',
        'justify-center',
        'ring-1',
        'ring-inset',
        'rounded-xl',
        'bg-gray-50',
        'dark:bg-gray-400/10',
        'text-gray-700',
        'dark:text-gray-400',
        'ring-gray-500/20 dark:ring-gray-400/20',
    ]

    const onClick = () => {
        moduleHelpers.handleClick(props.config.data)
    }

</script>

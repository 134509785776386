import { Preferences } from '@capacitor/preferences'

/**
 * The Preferences API provides a simple key/value persistent store for lightweight data.
 * Mobile OSs may periodically clear data set in window.localStorage, so this API should be used instead.
 * This API will fall back to using localStorage when running as a Progressive Web App.
* */

const buildKey = (key) => {
    return `list-filter:${key}`
}

export const filterPreferences = {

    async getEnabledFilters(key) {
        const data = await Preferences.get({ key: buildKey(key) })
        return data.value ? JSON.parse(data.value) : []
    },

    async setEnabledFilters(key, filters) {
        const value = JSON.stringify(filters)
        await Preferences.set({
            key: buildKey(key),
            value,
        })
    },
}

<template>
    <div data-cy="dashboard-section" class="pointer-events-none absolute w-full h-full" :style="{ backgroundColor: backgroundHex }">
        <!-- images -->
        <template v-if="!backgroundHex || backgroundHex.length === 0">

            <div class="relative h-full w-full">
                <div
                    v-for="(image, imageIndex) in images"
                    :key="image.file.uuid"
                    :style="`background-image: url(${image.file.url}); background-position: ${image.focus_x * 100}% ${image.focus_y * 100}%;`"
                    class="pointer-events-none absolute inset-0 bg-cover bg-center bg-no-repeat transition-all duration-300"
                    :class="{
                        'opacity-0': imageIndex !== currentVisibleImage,
                        'opacity-100': imageIndex === currentVisibleImage,
                    }"
                />
            </div>

            <div
                class="absolute inset-0"
                :style="{
                    background: tintColor,
                    opacity: tintOpacity,
                }"
            />
        </template>
    </div>

    <div
        v-if="graphicOverlay"
        class="absolute z-10"
        :class="[
            gridPosition(graphicOverlayPosition),
            paddingClasses,
            {
                'rounded-md': paddingClasses !== '',
                '!left-0 !right-0 !translate-x-0 flex items-center justify-center !rounded-none': fullWidthContainer,
                '!justify-start': fullWidthContainer && graphicOverlayPosition.includes('left'),
                '!justify-end': fullWidthContainer && graphicOverlayPosition.includes('right'),
            },
        ]"
        :style="{
            backgroundColor: addBackground ? graphicBackgroundHex : 'transparent',
            width: !fullWidthContainer ? graphicWidth : null,
        }"
    >
        <div :style="{ width: fullWidthContainer ? graphicWidth : null }">
            <img
                :src="graphicOverlay.url"
                alt="logo"
                class="w-full"
            />
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        item: Object,
        index: Number,
    })

    const imageTimer = ref(null)
    const currentVisibleImage = ref(0)

    const images = computed(() => get(props.item.data, 'images'))
    const backgroundHex = computed(() => get(props.item.data, 'background_color'))

    const tintColor = computed(() => get(props.item.data, 'tint_color'))
    const tintOpacity = computed(() => get(props.item.data, 'tint_opacity'))

    const graphicOverlay = computed(() => get(props.item.data, 'graphic_overlay_file'))
    const graphicSize = computed(() => get(props.item.data, 'graphic_size'))
    const fullWidthContainer = computed(() => get(props.item.data, 'full_width_container'))
    const graphicOverlayPosition = computed(() => {
        const position = get(props.item.data, 'graphic_overlay_position')
        if (isNil(position)) {
            return 'center'
        }
        return position
    })

    const addPadding = computed(() => get(props.item.data, 'add_padding'))
    const padding = computed(() => get(props.item.data, 'padding'))

    const addBackground = computed(() => get(props.item.data, 'add_background'))
    const graphicBackgroundHex = computed(() => {
        const hex = get(props.item.data, 'graphic_background_color')
        if (isNil(hex)) {
            return '#ffffff'
        }
        return hex
    })

    const graphicWidth = computed(() => {
        switch (graphicSize.value) {
            case 'lg':
                return '21.25rem'
            case 'md':
                return '10.625rem'
            case 'sm':
                return '7.5rem'
            default:
                return ''
        }
    })

    const paddingClasses = computed(() => {
        switch (padding.value) {
            case 'sm':
                return 'p-4'
            case 'md':
                return 'p-6'
            case 'lg':
                return 'p-8'
            case 'none':
            default:
                return ''
        }
    })

    const gridPosition = (position) => {
        switch (position) {
            case 'offset-top-left':
                return 'left-0 top-0 -translate-y-1/2'
            case 'offset-top':
                return 'left-1/2 top-0 -translate-y-1/2 -translate-x-1/2'
            case 'offset-top-right':
                return 'right-0 top-0 -translate-y-1/2'
            case 'top-left':
                return 'left-0 top-0'
            case 'top':
                return 'left-1/2 top-0 -translate-x-1/2'
            case 'top-right':
                return 'right-0 top-0'
            case 'left':
                return 'left-0 top-1/2 -translate-y-1/2'
            case 'right':
                return 'right-0 top-1/2 -translate-y-1/2'
            case 'bottom-left':
                return 'left-0 bottom-0'
            case 'bottom':
                return 'left-1/2 bottom-0 -translate-x-1/2'
            case 'bottom-right':
                return 'right-0 bottom-0'
            case 'offset-bottom-left':
                return 'left-0 bottom-0 translate-y-1/2'
            case 'offset-bottom':
                return 'left-1/2 bottom-0 translate-y-1/2 -translate-x-1/2'
            case 'offset-bottom-right':
                return 'right-0 bottom-0 translate-y-1/2'
            case 'center':
            default:
                return 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
        }
    }

    onMounted(() => {
        // if theres multiple images then start a timer to rotate whats visible
        if (images.value && images.value.length > 1) {
            if (imageTimer.value) {
                clearInterval(imageTimer.value)
            }
            imageTimer.value = setInterval(() => {
                currentVisibleImage.value += 1
                if (currentVisibleImage.value >= images.value.length) {
                    currentVisibleImage.value = 0
                }
            }, 10000)
        }
    })

    onBeforeUnmount(() => {
        clearInterval(imageTimer.value)
    })

</script>

export default Object.freeze({
    action: 'action',
    apiAction: 'api-action',
    apiComponent: 'api-component',
    component: 'component',
    modal: 'modal',
    external: 'external',
    externalApp: 'external-app',
    prompt: 'prompt',
})

<template>
    <DNotification
        :show="active"
        :type="notificationType"
        @close="onClose"
        @mouseover="stopTimer"
        @mouseleave="startTimer"
    >

        <component
            v-if="snackbar.component"
            :is="snackbar.component"
            v-bind="snackbar.props"
        />
        <template v-else>
            <div v-if="title" class="font-medium mb-1">
                {{ title }}
            </div>
            <div class="font-normal">
                {{ message }}
            </div>
        </template>

        <template v-slot:buttons v-if="buttons && !snackbar.component">
            <DButton
                v-for="(button, index) in buttons"
                :key="index"
                theme="transparent"
                @click="action(button)"
            >
                {{ button.title }}
            </DButton>
        </template>
    </DNotification>
</template>

<script setup>
    import { DButton, DNotification } from '@digistorm/spark'

    const props = defineProps({
        snackbar: Object,
    })

    const { close } = useSnackbars()

    const active = ref(true)
    const timer = ref()

    const title = computed(() => {
        return get(props.snackbar, 'title')
    })

    const message = computed(() => {
        return get(props.snackbar, 'message')
    })

    const notificationType = computed(() => {
        return get(props.snackbar, 'type', 'message')
    })

    const indefinite = computed(() => {
        return get(props.snackbar, 'indefinite', false)
    })

    const buttons = computed(() => {
        return get(props.snackbar, 'buttons')
    })

    const duration = computed(() => {
        return get(props.snackbar, 'duration', 8000)
    })

    const stopTimer = () => {
        clearTimeout(timer.value)
    }

    const onClose = () => {
        stopTimer()
        active.value = false

        delay(() => {
            close(props.snackbar.id)

            if (props.snackbar.onClose && isFunction(props.snackbar.onClose)) {
                props.snackbar.onClose()
            }
        }, 300)
    }

    const startTimer = () => {
        timer.value = setTimeout(() => {
            onClose()
        }, duration.value)
    }

    const action = (button) => {
        button.callback()
        onClose()
    }

    onMounted(() => {
        if (!indefinite.value) {
            startTimer()
        }
    })

</script>

import { defineStore } from 'pinia'
import { get } from 'lodash'

export const useTabStore = defineStore('tab', {
    state: () => {
        return {
            tabGroups: {},
        }
    },
    actions: {
        getCurrentTabId(tabGroup) {
            return get(this.tabGroups, `${tabGroup}.currentTab`)
        },
        setCurrentTabId(tabGroup, tab) {
            console.log('setting current tab', tabGroup, tab)
            if (!tabGroup || !tab) {
                // Don't save null values
                return
            }
            if (!this.tabGroups[tabGroup]) {
                this.tabGroups[tabGroup] = {}
            }
            this.tabGroups[tabGroup].currentTab = tab
        },
    },
})

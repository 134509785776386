<template>
    <div data-cy="more" class="relative h-full text-gray-700 flex flex-col" :class="{ 'pt-[var(--beyond-safe-area-inset-top)]': !isNativePlatform }">
        <div class="py-5 text-gray-900 font-medium text-xl text-center">
            More
        </div>

        <div v-if="loading" class="py-10 flex items-center justify-center h-full">
            <UILoading class="w-12 h-12" />
        </div>
        <div v-else-if="error" class="p-4">
            <DAlert type="danger" title="Error">
                Please try again later
            </DAlert>
        </div>
        <ModuleComponents
            v-else-if="more"
            :modules="[more]"
        />
    </div>
</template>

<script setup>
    import { DAlert } from '@digistorm/spark'
    import { Capacitor } from '@capacitor/core'
    import { statusBar, mock } from '@/services'
    import ModuleComponents from '@/components/ModuleComponents.vue'
    import { useMoreStore } from '@/stores/more'

    const props = defineProps({
        content: Object,
    })

    const moduleStore = useModuleStore()
    const appStore = useAppStore()
    const deviceStore = useDeviceStore()
    const moreStore = useMoreStore()
    const { more } = storeToRefs(moreStore)

    const moduleHelpers = useModuleHelpers()
    const router = useIonRouter()

    const { module } = storeToRefs(moduleStore)

    const loading = ref(null)
    const error = ref(null)

    const isNativePlatform = computed(() => {
        return Capacitor.isNativePlatform()
    })

    onMounted(() => {
        statusBar.setTheme()
        loading.value = true
        moreStore.load()
            .then(() => {
                loading.value = false
            })
            .catch((e) => {
                error.value = e
            })
            .finally(() => {
                loading.value = false
            })
    })

    onActivated(() => {
        statusBar.setTheme()
    })
</script>

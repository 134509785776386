<template>
    <div class="pt-6 h-[500px] flex flex-col">
        <p class="text-lg font-medium px-4 py-2 border-b border-transparent transition-colors duration-150" :class="{ '!border-gray-300': scrollY > 0 }">{{group.title}}</p>
        <IonContent ref="contentScroll" class="flex-1" scroll-events @ion-scroll="onScroll">
            <UISimple @click="toggleAllOptions">
                <template v-slot:title>
                    <span class="font-medium">Select all ({{group.options.length}})</span>
                </template>
                <template v-slot:right-content>
                    <DSwitch class="pointer-events-none" :model-value="allSelected" />
                </template>
            </UISimple>

            <UISimple
                v-for="option in group.options"
                :key="option.value"
                :title="option.title"
                @click="toggleOption(option)"
            >
                <template v-slot:right-content>
                    <DSwitch class="pointer-events-none" :model-value="option.active" />
                </template>
            </UISimple>
        </IonContent>
    </div>
</template>

<script setup>
    import { DSwitch } from '@digistorm/spark'

    const props = defineProps({
        group: Object,
        selectedOptions: {
            type: Array,
            default: () => {
                return []
            },
        },
        updateOptions: Function,
    })

    const scrollY = ref(0)

    const allSelected = computed(() => {
        return !find(props.group.options, { active: false })
    })

    const toggleOption = (option, value) => {
        option.active = value ?? !option.active

        if (isFunction(props.updateOptions)) {
            props.updateOptions()
        }
    }

    const toggleAllOptions = () => {
        if (allSelected.value) {
            each(props.group.options, (option) => {
                toggleOption(option, false)
            })
        } else {
            each(props.group.options, (option) => {
                toggleOption(option, true)
            })
        }
    }

    const onScroll = (e) => {
        scrollY.value = e.detail.scrollTop
    }
</script>

import { get } from 'lodash'

export function useAuthHelpers() {
    function isAuthenticationException(e) {
        return get(e, 'response.status') === 403
            && [
                'App\\Exceptions\\CredentialMissingException',
                'App\\Exceptions\\CredentialInvalidException',
            ].includes(get(e, 'response.data.type'))
    }

    function handleAuthenticationException(data, router, loginHandler, abortHandler = null, push = false) {
        const authStore = useAuthStore()
        authStore.setAuthRequirements(data.requirements)
        authStore.setLoginSuccessHandler(loginHandler)
        authStore.setLoginAbortHandler(abortHandler)

        const method = push ? router.push : router.replace
        method({ name: 'login' })
    }

    return {
        isAuthenticationException,
        handleAuthenticationException,
    }
}

<template>
    <div
        class="flex w-full space-x-2 items-start"
        data-cy="card-detail-row"
    >
        <component
            v-for="(item, index) in item.details"
            :key="index"
            :is="cardDetailTypes[item.type]"
            :item="item"
            @click="emit('click', $event)"
            class="basis-full"
        />
    </div>
</template>

<script setup>
    import cardDetailTypes from '@/constants/cardDetailTypes'

    const props = defineProps({
        item: Object,
    })

    const emit = defineEmits(['click'])
</script>

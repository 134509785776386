<template>
    <UISimple @click="toggleMockData">
        <template v-slot:title>Demo data</template>
        <template v-slot:right-content>
            <DSwitch :model-value="mockData" class="pointer-events-none" />
        </template>
    </UISimple>
</template>

<script setup>
    import { DSwitch } from '@digistorm/spark'

    const appStore = useAppStore()

    const { mockData } = storeToRefs(appStore)

    const toggleMockData = () => {
        if (mockData.value) {
            appStore.disableMockData()
        } else {
            appStore.enableMockData()
        }
    }
</script>

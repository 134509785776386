import axios from 'axios'
import { get, has, startsWith } from 'lodash'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
    (config) => {
        if (!axiosInstance.defaults.baseURL) {
            throw new Error('API URL needs to be configured')
        }

        const isApiRequest = startsWith(config.url, '/')
            || startsWith(config.url, axiosInstance.defaults.baseURL)

        // If the request isn't an API request don't add our authentication servers
        if (!isApiRequest) {
            return config
        }

        const appStore = useAppStore()
        const authStore = useAuthStore()

        // Add the bearer token
        if (!config.headers.Authorization && authStore.token) {
            config.headers.Authorization = `Bearer ${authStore.token}`
        }

        // Add the user decryption key
        if (!config.headers['X-USER-KEY'] && authStore.userKey) {
            config.headers['X-USER-KEY'] = authStore.userKey
        }

        // Add the mock data header
        if (appStore.mockData) {
            config.headers['X-MOCK-DATA'] = true
        }

        if (appStore.draftVersion) {
            config.headers['X-APP-VERSION'] = appStore.draftVersion
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    (response) => {
        const authStore = useAuthStore()

        // Check if we get a X-REFRESH-REQUIRED header and trigger a refresh
        if (has(response.headers, 'X-REFRESH-REQUIRED')) {
            console.info('Token refresh required')
            authStore.refresh()
        }
        return response
    },
    (error) => {
        const authStore = useAuthStore()
        if (get(error, 'response.status') === 401) {
            authStore.logout()

            // Navigate back
            window.location.href = '/'
        }

        // Check if the error is maintenance mode
        if (get(error, 'response.status') === 503
            && get(error, 'response.data.type') === 'App\\Exceptions\\MaintenanceModeException') {
            window.localStorage.setItem('maintenance.title', get(error, 'response.data.title'))
            window.localStorage.setItem('maintenance.message', get(error, 'response.data.message'))
            window.location.href = '/maintenance'
        }

        // If is a validation error, we don't log out the user
        if (get(error, 'response.status') === 422) {
            throw error
        }

        // If error was not 401 just reject as is
        throw error
    },
)

export default axiosInstance

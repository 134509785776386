<template>
    <div v-if="!loading && !dismissed">
        <div class="bg-white rounded-lg divide-y divide-gray-200">
            <div>
                <div class="flex items-start space-x-4 w-full px-6 py-4">
                    <div class="flex-1 pt-0.5">
                        <p class="font-medium text-sm" style="overflow-wrap: anywhere;">{{ config.data.title }}</p>
                        <p v-if="config.data.subtitle" class="text-gray-600 text-sm mt-1">{{ config.data.subtitle }}</p>
                    </div>
                </div>
            </div>
            <div @click="onClick" @keydown.enter="onClick" class="active:bg-gray-100">
                <div class="flex items-start space-x-4 w-full px-6 py-4">
                    <div class="flex-1 pt-0.5">
                        <p class="font-medium text-sm text-center" style="overflow-wrap: anywhere;">
                            <span>Dismiss</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import api from '@/services/api'

    const props = defineProps({
        config: Object,
    })

    const moduleHelpers = useModuleHelpers()

    const dismissed = ref(false)
    const loading = ref(false)

    const onClick = () => {
        if (!props.config.data.url) {
            dismissed.value = true
            return
        }
        loading.value = true
        return api.post(props.config.data.url)
            .then(({ data }) => {
                moduleHelpers.handleActions(data.actions)
                dismissed.value = true
            })
            .finally(() => {
                // If the request failed the alert will come back
                loading.value = false
            })
    }
</script>

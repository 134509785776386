import { BiometricAuth } from '@aparajita/capacitor-biometric-auth'
import { BiometryType } from '@aparajita/capacitor-biometric-auth/dist/esm/definitions'
import { preferences } from '@/services/preferences'

export const biometric = {

    biometryTypeFromId: (typeId) => {
        switch (typeId) {
            case BiometryType.touchId:
                return 'Touch ID'
            case BiometryType.faceId:
                return 'Face ID'
            case BiometryType.fingerprintAuthentication:
                return 'Fingerprint Authentication'
            case BiometryType.faceAuthentication:
                return 'Face Authentication'
            case BiometryType.irisAuthentication:
                return 'Iris Authentication'
            default:
                return 'Biometric Login'
        }
    },

    authenticate: async () => {
        await BiometricAuth.authenticate()
    },

    addResumeListener: () => {
        return BiometricAuth.addResumeListener(
            biometric.updateBiometryInfo,
        )
    },

    setEnabled: async (enabled) => {
        if (!enabled) {
            await preferences.removeGlobalItem('biometrics_enabled')
            return
        }
        await preferences.setGlobalItem({ key: 'biometrics_enabled', value: '1' })
    },

    isEnabled: async () => {
        return preferences.getGlobalItem('biometrics_enabled')
    },

    setPreviouslyRequested: async () => {
        await preferences.setGlobalItem({ key: 'biometrics_previously_requested', value: '1' })
    },

    hasPreviouslyRequested: async () => {
        return preferences.getGlobalItem('biometrics_previously_requested')
    },

    canRequestBiometry: async () => {
        const hasPreviouslyRequested = await biometric.hasPreviouslyRequested()
        const isEnabled = await biometric.isEnabled()
        const biometricsAvailable = await biometric.biometricsAvailable()

        return !hasPreviouslyRequested && !isEnabled && biometricsAvailable
    },

    biometricsAvailable: async () => {
        const biometricDetails = await biometric.biometricDetails()
        return biometricDetails.isAvailable
    },

    biometricDetails: async () => {
        return BiometricAuth.checkBiometry()
    },

    biometryType: async () => {
        const biometricDetails = await biometric.biometricDetails()
        return biometric.biometryTypeFromId(biometricDetails.biometryType)
    },

    updateBiometryInfo: async () => {
        if (!await biometric.biometricsAvailable()) {
            console.log('biometrics disabled')
            await preferences.removeGlobalItem('biometrics_enabled')
        }
    },
}

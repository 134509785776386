import { defineStore } from 'pinia'
import { Capacitor } from '@capacitor/core'
import { api, mock } from '@/services'

export const useNotificationsStore = defineStore('notifications', {
    state: () => {
        return {
            component: {},
            loading: false,
            error: null,
            lastFetched: null,
            enabled: true,
        }
    },
    getters: {
        notifications(state) {
            return state.component?.items ?? []
        },
        unreadCount(state) {
            return this.notifications.filter((item) => !item.data?.read).length
        },
    },
    actions: {
        async fetch(force = false) {
            const moduleStore = useModuleStore()
            const appStore = useAppStore()

            if (!this.enabled) {
                return
            }

            // If mocking use the mock content
            if (mock.mockingNotifications()) {
                this.component = mock.content()
                return
            }

            if (force) {
                this.lastFetched = null
            }

            if (this.component && this.lastFetched && this.lastFetched > Date.now() - 5000) {
                // Don't reload more often than every 5 seconds
                return
            }
            this.lastFetched = Date.now()
            this.error = null
            this.loading = true
            api.get(`/${appStore.id}/push/endpoints`)
                .then(({ data }) => {
                    // Request all platform notifications
                    const requests = map(data.endpoints, (endpoint) => api.get(endpoint.url))

                    // Wait for all platforms to return
                    return Promise.allSettled(requests)
                        .then((results) => {
                            const notificationItems = []

                            // Combine all items into one list
                            results.forEach((result) => {
                                // Skip failed requests
                                if (result.status !== 'fulfilled') {
                                    return
                                }
                                notificationItems.push(...result.value.data.items)
                            })

                            // Sort notifications and render component
                            this.component = {
                                meta: data.meta,
                                items: chain(notificationItems)
                                    .orderBy('date', 'desc')
                                    .map('item')
                                    .value(),
                                component: 'NotificationList',
                            }
                        })
                })
                .catch((e) => {
                    this.error = e
                })
                .finally(() => {
                    this.loading = false
                })
        },
        removeAllDeliveredNotifications() {
            if (Capacitor.isNativePlatform()) {
                const pushNotifications = usePushNotifications()
                // Remove the notifications so the badge on the app icon disappears
                pushNotifications.removeAllDeliveredNotifications()
            }
        },
        setNotificationsEnabled(isEnabled) {
            this.enabled = isEnabled
            if (isEnabled) {
                this.fetch()
            }
        },
    },
})

export function useKeygen() {
    function generate() {
        const bytes = new Uint8Array(32)
        // load cryptographically random bytes into array
        window.crypto.getRandomValues(bytes)
        return btoa(String.fromCharCode(...bytes))
    }

    return {
        generate,
    }
}

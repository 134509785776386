<template>
    <div class="pb-[var(--beyond-safe-area-inset-bottom)]">
        <div data-cy="notification-settings">
            <NotificationToggle title="Allow push notifications" @toggle="enabled = $event" />

            <template v-if="enabled || faking">
                <ModuleComponents :modules="props.config.items" />

                <div class="flex p-4">
                    <div>
                        <PhIcon icon="info" weight="regular" :size="24" class="text-primary-500 mr-2 shrink-0" />
                    </div>
                    <div class="text-sm text-gray-700">
                        You will only receive notifications for content you have selected in subscriptions.
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { storeToRefs } from 'pinia'
    import NotificationToggle from '@/components/modules/NotificationToggle.vue'
    import PhIcon from '@/components/app/PhIcon.vue'
    import ModuleComponents from '@/components/ModuleComponents.vue'

    const props = defineProps({
        config: Object,
    })

    const appStore = useAppStore()
    const moduleHelpers = useModuleHelpers()
    const moduleStore = useModuleStore()
    const router = useIonRouter()
    const pushNotifications = usePushNotifications()
    const deviceStore = useDeviceStore()

    const { mockData } = storeToRefs(appStore)
    const { isWeb } = storeToRefs(deviceStore)
    const enabled = ref(true)

    const faking = computed(() => {
        return isWeb.value || mockData.value
    })

    onMounted(async () => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
        enabled.value = await pushNotifications.enabled()
    })
</script>

import { defineStore } from 'pinia'

import { api, mock } from '@/services'
import { useAppStore } from '@/stores/app'

export const useMoreStore = defineStore('more', {
    state: () => {
        return {
            loaded: false,
            more: {},
        }
    },
    actions: {
        // Loading of the more module content
        async load() {
            const { appId } = useAppStore()
            // If mocking use the mock content
            if (mock.mockingMore()) {
                this.more = mock.content()
                return
            }
            await api.get(`/${appId}/more`)
                .then(({ data }) => {
                    this.more = data
                    this.loaded = true
                })
        },
    },
})

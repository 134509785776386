<template>
    <div class="relative border border-gray-300 rounded-lg overflow-hidden mx-4 mt-6" @click="emit('click')">
        <div class="relative">
            <div v-if="header" class="bg-secondary px-4 py-3 text-sm font-medium text-gray-800 border-b border-base flex items-center space-x-2">
                <UIIcon v-if="header.icon" :icon="header.icon" weight="fill" :size="16" class="text-gray-500" />
                <span>{{header.title}}</span>
            </div>
            <div v-else-if="title" class="text-gray-600 px-4 text-[13px] uppercase tracking-wider mb-2 mt-4">
                {{title}}
            </div>
            <DBadge
                v-if="badge"
                class="absolute left-1/2 -translate-x-1/2 top-0"
                :color="badge.color"
                :icon="badge.icon"
                rounded
            >
                {{badge.text}}
            </DBadge>
        </div>
        <div
            v-if="$slots.default"
            class="px-4 pb-3.5"
        >
            <slot />
        </div>
    </div>
</template>

<script setup>
    import { DBadge } from '@digistorm/spark'

    import UIIcon from '@/components/ui/UIIcon.vue'

    const emit = defineEmits(['click'])

    const icon = ref({
        icon: 'house',
    })

    const props = defineProps({
        title: String,
        header: Object,
        badge: Object,
    })
</script>

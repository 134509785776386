<template>
    <div class="mt-5 mb-8 px-4 flex items-center">
        <UIIcon v-if="icon" :icon="icon" :size="24" weight="regular" />
        <UIHtml :html="config.data.body" class="prose prose-sm max-w-full ml-2" style="overflow-wrap: anywhere;" />
    </div>
</template>

<script setup>
    import UIHtml from '@/components/ui/UIHtml.vue'
    import UIIcon from '@/components/ui/UIIcon.vue'

    const props = defineProps({
        config: Object,
    })

    const icon = ref(props.config.data.icon)
</script>

<template>
    <PhIcon v-if="icon?.icon" :icon="icon.icon" :size="size" :weight="weight" :color="color" />
    <!-- Wrap SVG in div in case it doesn't load -->
    <div v-else-if="icon?.icon_url" :style="sizeStyle">
        <InlineSvg
            :src="icon.icon_url"
            alt="icon"
            :style="{
                color,
                ...sizeStyle,
            }"
        />
    </div>
    <div v-else-if="icon?.system_icon" :style="sizeStyle">
        <InlineSvg
            alt="icon"
            :src="`/icons/${icon.system_icon}.svg`"
            :style="{
                color,
                ...sizeStyle,
            }"
        />
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    import InlineSvg from 'vue-inline-svg'

    const props = defineProps({
        icon: Object,
        weight: {
            type: String,
            default: 'light',
        },
        size: {
            type: Number,
            default: 24,
        },
        color: String,
    })

    const sizeStyle = computed(() => {
        return {
            width: `${props.size}px`,
            height: `${props.size}px`,
        }
    })

    const color = computed(() => {
        return props.color ?? props.icon.color
    })
</script>

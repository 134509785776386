<template>
    <div
        ref="buttonElement"
        data-cy="dashboard-button"
        class="relative"
        @click="onButtonClicked"
        @keydown.enter="onButtonClicked"
    >

        <div
            ref="buttonContentElement"
            class="flex items-center overflow-hidden w-full h-full border-transparent"
            :class="[
                buttonStyleClasses,
                {
                    'shadow-md': buttonData.add_shadow,
                    border: buttonData.icon_style === 'thin' && buttonData.add_border,
                    'border-2': buttonData.icon_style !== 'thin' && buttonData.add_border,
                    'rounded-full aspect-square max-h-[180px] max-w-[180px] mx-auto': buttonData.circular,
                },
            ]"
            :style="{
                borderRadius: !buttonData.circular ? `${buttonRadius}px` : null,
                backgroundColor: buttonData.background_color,
                borderColor: buttonData.border_color,
            }"
        >
            <div
                v-if="buttonData.style !== 'text'"
                ref="iconElement"
                class="flex items-center justify-center border-2 flex-shrink-0"
                :class="{
                    'p-2': buttonData.icon_container,
                    'border-transparent': !buttonData.icon_container,
                }"
                :style="{
                    backgroundColor: buttonData.icon_container ? buttonData.icon_background : 'transparent',
                    borderColor: buttonData.icon_container ? buttonData.icon_border_color : 'transparent',
                    borderRadius: buttonData.icon_container ? iconRadius : '',
                }"
            >
                <UIIcon
                    :icon="iconData"
                    :size="buttonData.icon_style === 'fill' || buttonData.icon_style === 'light' ? 32 : 40"
                    :weight="buttonData.icon_style || 'fill'"
                />
            </div>

            <span
                v-if="buttonData.style !== 'icon'"
                class="relative text-sm leading-tight min-h-[35px] flex flex-col items-center justify-center"
                :style="{
                    color: buttonData.text_color,
                }"
            >
                {{buttonText}}
            </span>
        </div>

        <div
            v-if="badge"
            class="text-sm font-medium absolute flex items-center justify-center w-6 h-6 bg-red-700 text-white rounded-full"
            :class="{ 'top-1 right-1': !buttonData.circular }"
            :style="buttonData.circular ? badgeCircularOffsetPosition : null"
        >
            {{badge}}
        </div>

    </div>
</template>

<script setup>
    import { useElementSize } from '@vueuse/core'
    import UIIcon from '@/components/ui/UIIcon.vue'

    const props = defineProps({
        button: Object,
        buttonGroup: Object,
    })

    const router = useIonRouter()

    const moduleStore = useModuleStore()
    const badgeStore = useBadgeStore()

    const { validateColor } = useHelpers()
    const { homepageAnimation } = useAnimations()
    const moduleHelpers = useModuleHelpers()

    const buttonElement = ref(null)
    const buttonContentElement = ref(null)
    const iconElement = ref(null)
    const clicking = ref(false)

    const { height: buttonElementHeight, width: buttonElementWidth } = useElementSize(buttonElement, { width: 0, height: 0 }, { box: 'border-box' })
    const { width: buttonContentElementWidth } = useElementSize(buttonContentElement, { width: 0, height: 0 }, { box: 'border-box' })
    const { width: iconElementWidth } = useElementSize(iconElement, { width: 0, height: 0 }, { box: 'border-box' })

    // button data with button group defaults
    const buttonData = computed(() => {
        // filter out anything null from the button
        const button = pickBy(get(props.button, 'data'), (value) => {
            return !isNil(value)
        })

        return assign({}, get(props.buttonGroup, 'data.button', {}), button)
    })

    const { getBadgeForUrl } = storeToRefs(badgeStore)

    const badgeUrl = computed(() => {
        return get(props.button, 'data.badge_url')
    })

    const badge = computed(() => {
        if (!badgeUrl.value) {
            return
        }
        return getBadgeForUrl.value(badgeUrl.value) > 99 ? '+99' : getBadgeForUrl.value(badgeUrl.value)
    })

    const buttonText = computed(() => {
        const text = get(buttonData.value, 'text')
        if (isNil(text)) {
            return 'Button'
        }
        return text
    })

    const iconUrl = computed(() => {
        const icon = get(buttonData.value, 'icon_data')
        if (icon?.file) {
            return icon.file.url
        }
        if (icon?.custom) {
            if (icon.custom.single) {
                return icon.custom.single.url
            }
            return icon.custom[buttonData.value.icon_style].url
        }
        return null
    })

    const iconData = computed(() => {
        if (buttonData.value.icon_data?.icon) {
            return {
                icon: buttonData.value.icon_data.icon,
                icon_url: null,
                color: buttonData.value.icon_color,
            }
        }

        if (iconUrl.value) {
            return {
                icon: null,
                icon_url: iconUrl.value,
                color: buttonData.value.icon_color,
            }
        }

        // Default icon
        return {
            icon: 'users',
            icon_url: null,
            color: buttonData.value.icon_color,
        }
    })

    const buttonRadius = computed(() => {
        if (!buttonElement.value) {
            return
        }

        let buttonHeight = props.buttonHeight

        if (!props.buttonHeight || props.buttonHeight === 0 || props.buttonHeight === 'auto') {
            buttonHeight = buttonElementHeight.value
        }

        return buttonHeight * buttonData.value.radius
    })

    const badgeCircularOffsetPosition = computed(() => {
        const badgeWidth = 6
        const badgeWidthRem = badgeWidth / 4
        const radians = -45 * (Math.PI / 180)

        // Position the badge so it's centered on the edge of the circle
        const radius = buttonContentElementWidth.value / 2
        const left = (buttonElementWidth.value / 2) + Math.cos(radians) * radius
        const top = (buttonElementHeight.value / 2) + Math.sin(radians) * radius

        return {
            left: `calc(${left}px - ${badgeWidthRem / 2}rem)`,
            top: `calc(${top}px - ${badgeWidthRem / 2}rem)`,
        }
    })

    const buttonStyleClasses = computed(() => {
        let colButtonClasses = 'flex-col space-y-1 justify-center py-3 text-center px-1.5'
        if (!buttonData.value.circular) {
            colButtonClasses += ' min-h-[115px]'
        }
        switch (buttonData.value.style) {
            case 'row':
                return 'relative space-x-2 py-2 px-2'
            case 'icon':
            case 'text':
                return 'relative space-x-2 justify-center text-center py-3 px-2'
            case 'col':
            default:
                return colButtonClasses
        }
    })

    const iconRadius = computed(() => {
        if (!iconElement.value) {
            return
        }
        const iconWidth = iconElementWidth.value
        return `${iconWidth * buttonData.value.icon_radius}px`
    })

    const onButtonClicked = (e) => {
        if (clicking.value || !buttonData.value.type) {
            return
        }

        clicking.value = true

        // Convert the button link data to a standard link data
        moduleHelpers.getModuleOrOpenBrowserForLinkData(buttonData.value)
            .then((appModule) => {
                if (!appModule || !appModule.id) {
                    return
                }
                router.push({ name: 'view', params: { id: appModule.id } }, homepageAnimation)
            })
            .finally(() => {
                clicking.value = false
            })
    }

    const fetchBadge = () => {
        if (badgeUrl.value) {
            badgeStore.fetch(badgeUrl.value)
        }
    }

    onMounted(() => {
        fetchBadge()
        events.on('refresh-badges', fetchBadge)
    })

    onUnmounted(() => {
        events.off('refresh-badges', fetchBadge)
    })
</script>

<template>
    <div
        class="group relative flex-shrink-0"
        :id="`block-display-${index}`"
        :class="{
            'flex-1 min-h-[150px]': height === 'flex',
            'z-10': !isButtonGroup,
            'z-20': isButtonGroup,
        }"
        :style="{
            height: `${height * dashboardHeight}px`,
        }"
    >
        <component
            :is="blockComponent"
            :item="item"
            :index="index"
        />
    </div>
</template>

<script setup>
    import { inject } from 'vue'

    import blocks from './components'

    const dashboardHeight = inject('dashboardHeight')

    const props = defineProps({
        item: Object,
        index: Number,
        total: Number,
    })

    const blockComponent = computed(() => blocks[`${get(props.item, 'component')}Block`])

    const height = computed(() => {
        const height = get(props.item.data, 'height')
        if (!isNil(height)) {
            return height
        }
        return props.item.component === 'Section' ? 'flex' : 'auto'
    })

    const isButtonGroup = computed(() => props.item.component === 'ButtonGroup')

</script>

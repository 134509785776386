import { v4 as uuidv4 } from 'uuid'

export const useSnackbars = createGlobalState(
    () => {
        const snackbars = shallowReactive([])

        const snackbar = (options) => {
            snackbars.push({
                id: uuidv4(),
                ...options,
            })
        }

        const close = (id) => {
            snackbars.splice(findIndex(snackbars, { id }), 1)
        }

        return {
            snackbars,
            snackbar,
            close,
        }
    },
)

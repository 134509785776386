<template>
    <div class="text-gray-600 pb-2 px-4 border-b pt-10 first:pt-5 text-[13px] uppercase font-normal tracking-wide">
        {{content.title}}
    </div>

    <ModuleComponents v-if="props.config.items" :modules="props.config.items" :filter="filter" />
</template>

<script setup>
    defineOptions({
        name: 'GroupListItem',
        inheritAttrs: false,
    })

    const props = defineProps({
        config: Object,
        filter: Function,
    })

    const content = computed(() => (props.config ? props.config.data : null))
</script>

<template>
    <UISimple
        v-for="(group, index) in groupsWithOptions"
        :key="index"
        @click="showGroupOptions(group)"
        :title="group.title"
        data-cy="subscriptions-item"
    >
        <template v-slot:right-text>
            {{selectedOptionCountByGroup[index]}}/{{group.options.length}}
        </template>
    </UISimple>
    <Empty v-if="!groupsWithOptions.length" />
</template>

<script setup>
    import { intersection } from 'lodash'
    import api from '@/services/api'
    import SubscriptionGroupModal from '@/components/app/settings/SubscriptionGroupModal.vue'

    let controller = null

    const props = defineProps({
        config: Object,
    })

    const moduleHelpers = useModuleHelpers()

    const content = computed(() => props.config.data)
    const groups = computed(() => content.value.groups)

    const activeGroup = ref(null)

    const groupsWithOptions = computed(() => filter(groups.value, (group) => group.options.length))

    const selectedOptionsAtLastSave = ref(null)
    const selectedOptions = computed(() => {
        const options = []
        each(groups.value, (group) => {
            each(group.options, (option) => {
                if (option.active) {
                    options.push(option.value)
                }
            })
        })
        return options
    })

    const selectedOptionCountByGroup = computed(() => {
        return mapValues(groupsWithOptions.value, (group) => {
            return intersection(map(group.options, 'value'), selectedOptions.value).length
        })
    })

    const updateOptions = () => {
        if (controller) {
            controller.abort()
        }

        controller = new AbortController()

        const values = selectedOptions.value
        api.patch(
            content.value.url,
            {
                values,
                previous_values: selectedOptionsAtLastSave.value,
            },
            {
                signal: controller.signal,
            },
        )
            .then(({ data }) => {
                selectedOptionsAtLastSave.value = values
                moduleHelpers.handleActions(data.actions)
            })
    }

    const showGroupOptions = async (group) => {
        activeGroup.value = group

        const modal = await modalController.create({
            component: SubscriptionGroupModal,
            componentProps: {
                group,
                selectedOptions: selectedOptions.value,
                updateOptions,
            },
            initialBreakpoint: 1,
            breakpoints: [0, 1],
        })
        modal.present()
    }

    onMounted(() => {
        selectedOptionsAtLastSave.value = selectedOptions.value
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })

</script>

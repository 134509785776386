export const mock = {
    mocking() {
        return !!window.mock
    },
    mockingDashboard() {
        return this.mocking() && window.mock.type === 'dashboard'
    },
    mockingModule() {
        return this.mocking() && window.mock.type === 'module'
    },
    mockingMore() {
        return this.mocking() && window.mock.type === 'more'
    },
    mockingNotifications() {
        return this.mocking() && window.mock.type === 'notifications'
    },
    content() {
        return window.mock.content
    },
    version() {
        return window.mock.version
    },
    dashboard() {
        return this.mockingDashboard()
            ? this.content().layout
            : {
                data: {
                    background_color: '#19424A',
                    background_image_file: null,
                    transparent_status_bar: false,
                },
                items: [],
            }
    },
}

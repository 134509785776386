<template>
    <div class="px-4">
        <label
            v-for="(radio, index) in items"
            @click="handleRadioSelected(radio)"
            :key="`radio-${index}`"
            type="button"
            class="w-full flex items-center p-5 border mb-4 rounded-xl transition-all"
            :class="{
                'border-primary-600': selected === radio.value,
                'border-gray-300': selected !== radio.value,
            }"
        >
            <UIAvatar v-if="radio.avatar" :avatar="radio.avatar" />
            <div v-if="radio.icon" class="bg-primary-50 p-2 rounded-full mr-4">
                <PhIcon class="text-primary-700" :size="24" :icon="radio.icon" weight="light" />
            </div>
            <div>
                <div class="text-gray-900">
                    {{ radio.title }}
                </div>
                <div class="text-gray-600 text-xs">
                    {{ radio.subtitle }}
                </div>
            </div>
            <input
                :id="radio.value"
                :value="radio.value"
                v-model="selected"
                name="radio"
                type="radio"
                class="ml-auto h-4 w-4 border-bold transition duration-150 ease-in-out cursor-pointer z-10 bg-base text-action-colour-subtle focus:ring-focus dark:ring-offset-mute hover:border-focus"
                @click.stop
            />
        </label>
    </div>
</template>

<script setup>

    import UIAvatar from '@/components/ui/UIAvatar.vue'
    import PhIcon from '@/components/app/PhIcon.vue'
    import { events } from '@/composables/events'

    const router = useIonRouter()
    const moduleHelpers = useModuleHelpers()
    const { snackbar } = useSnackbars()

    const props = defineProps({
        config: Object,
    })

    const items = ref(props.config.items)

    const errors = ref({})
    const submitting = ref(false)
    const selected = ref(null)

    const handleRadioSelected = (radio = null) => {
        const linkData = {
            link: {
                type: 'api-action',
                url: props.config.data.url,
                payload: {
                    value: radio.value,
                    ...radio.additional_data,
                },
            },
        }

        submitting.value = true
        events.emit('toggleLoadingOverlay', true)

        return moduleHelpers.handleClick(linkData)
            .then((appModule) => {
                if (!appModule || !appModule.id) {
                    return
                }
                router.push({ name: 'view', params: { id: appModule.id } })
            })
            .catch((error) => {
                if (error?.response?.status === 422) {
                    errors.value = error.response.data
                    return
                }

                console.error('error', error)
                snackbar({
                    message: 'Error submitting form',
                    type: 'error',
                })
                throw error
            })
            .finally(() => {
                submitting.value = false
                events.emit('toggleLoadingOverlay', false)
            })
    }

    onMounted(() => {
        selected.value = find(items.value, (radio) => radio.selected).value
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })
</script>

<template>
    <IonModal ref="modal" :trigger="uuid" :initial-breakpoint="1" :breakpoints="[0, 1]" v-if="filterItems.length">
        <div class="pt-6 h-[500px] flex flex-col">
            <p class="text-lg font-medium px-4 py-2 border-b border-transparent transition-colors duration-150" :class="{ '!border-gray-300': scrollY > 0 }">Filters</p>
            <IonContent ref="contentScroll" class="flex-1" scroll-events @ion-scroll="onScroll">
                <div class="pb-[var(--beyond-safe-area-inset-top)]">

                    <UISimple @click="toggleFilterMenuAll">
                        <template v-slot:title>Select all</template>
                        <template v-slot:right-content>
                            <DSwitch :model-value="allToggleFilterItemsSelected" class="pointer-events-none" />
                        </template>
                    </UISimple>

                    <UISimple
                        v-for="filterItem in filterItems"
                        :key="filterItem.value"
                        @click="toggleFilterMenuItem(filterItem.value)"
                    >
                        <template v-slot:title>{{ filterItem.title }}</template>
                        <template v-slot:right-content>
                            <DSwitch :model-value="filterItem.enabled" class="pointer-events-none" />
                        </template>
                    </UISimple>
                </div>
            </IonContent>
        </div>
    </IonModal>
</template>
<script setup>
    import { DSwitch } from '@digistorm/spark'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'

    const emit = defineEmits(['toggleAll', 'change'])

    const props = defineProps({
        uuid: String,
        items: Array,
    })

    const scrollY = ref(0)
    const filterMenuAll = ref(true)
    const filterItems = ref(props.items ?? [])

    const onScroll = (e) => {
        scrollY.value = e.detail.scrollTop
    }

    const selectedFilterMenuItems = computed(() => {
        return chain(filterItems.value)
            .filter((filter) => filter.enabled)
            .map('value')
            .value()
    })

    const allToggleFilterItemsSelected = computed(() => {
        return filterItems.value.length === selectedFilterMenuItems.value.length
    })

    const toggleFilterMenuAll = () => {
        const filterAllState = !allToggleFilterItemsSelected.value
        each(filterItems.value, (filterItem) => {
            filterItem.enabled = filterAllState
        })
        emit('toggleAll', filterAllState)
    }

    const toggleFilterMenuItem = (value) => {
        let hasFiltersEnabled = false
        each(filterItems.value, (filterItem) => {
            if (filterItem.value === value) {
                filterItem.enabled = !filterItem.enabled
            }
            hasFiltersEnabled = hasFiltersEnabled || filterItem.enabled
        })
        filterMenuAll.value = !hasFiltersEnabled
        emit('toggleAll', filterMenuAll.value)
    }

    watch(selectedFilterMenuItems, (val) => {
        emit('change', val)
    }, {
        deep: true,
    })
</script>

import { Preferences } from '@capacitor/preferences'

/**
 * The Preferences API provides a simple key/value persistent store for lightweight data.
 * Mobile OSs may periodically clear data set in window.localStorage, so this API should be used instead.
 * This API will fall back to using localStorage when running as a Progressive Web App.
* */

let prefix = 'default'

const buildKey = (key, global = false) => {
    return global ? key : `${prefix}:${key}`
}

const getItem = async (item, global) => {
    const data = await Preferences.get({ key: buildKey(item, global) })
    return data.value
}

const setItem = async ({ key, value, global }) => {
    await Preferences.set({
        key: buildKey(key, global),
        value,
    })
}

const removeItem = async (item, global) => {
    await Preferences.remove({ key: buildKey(item, global) })
}

export const preferences = {
    async setPrefix(value) {
        prefix = value ?? 'default'
    },

    async getItem(item) {
        return getItem(item, false)
    },

    async getGlobalItem(item) {
        return getItem(item, true)
    },

    async setItem({ key, value }) {
        return setItem({ key, value, global: false })
    },

    async setGlobalItem({ key, value }) {
        return setItem({ key, value, global: true })
    },

    async removeItem(item) {
        await removeItem(item, false)
    },

    async removeGlobalItem(item) {
        await removeItem(item, true)
    },

    async removeAll(global = false) {
        if (global) {
            await Preferences.clear()
            return
        }

        const result = await Preferences.keys()

        await Promise.all(
            result.keys
                .filter((key) => key.startsWith(`${prefix}:`))
                .map((key) => Preferences.remove({ key })),
        )
    },
    async useLegacyStorage(legacy = true) {
        await Preferences.configure({ group: legacy ? 'NativeStorage' : 'CapacitorStorage' })
    },
}

import { StatusBar, Style } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'
import tinycolor from 'tinycolor2'
import { useDeviceStore } from '@/stores'

const available = Capacitor.isPluginAvailable('StatusBar')

const setStyle = async (style) => {
    console.log(`Set status bar style: ${style}`)

    if (window.parent) {
        window.parent.postMessage({ type: 'beyond-status-bar-style', style }, '*')
    }

    if (!available) {
        return
    }

    if (Capacitor.isNativePlatform()) {
        await StatusBar.setStyle({ style })
    }
}

// Avoid using the device store here, so it's quicker to load
const isIos = () => {
    return Capacitor.getPlatform() === 'ios'
}

export const statusBar = {
    setTheme(color = '#ffffff') {
        this.setBackgroundTransparent(color)
        // Check primary color is light or dark and change the status bar
        if (tinycolor(color).isLight()) {
            this.setStyleLight()
        } else {
            this.setStyleDark()
        }
    },
    setStyleLight() {
        setStyle(Style.Light)
    },
    setStyleDark() {
        setStyle(Style.Dark)
    },
    setOverlaysWebView(overlay) {
        // This feature isn't supported on iOS
        if (!available || isIos()) return
        StatusBar.setOverlaysWebView({ overlay })
    },
    setBackgroundTransparent(color = '#ffffff') {
        // Set color with alpha channel bits to indicate it should be transparent
        // The other color information shouldn't be used, but keeping in case older android devices don't support it
        this.setBackgroundColor(`${color}00`)
    },
    setBackgroundColor(color) {
        // This feature isn't supported on iOS
        if (!available || isIos()) return
        StatusBar.setBackgroundColor({ color })
    },
    hide() {
        if (!available) return
        StatusBar.hide()
    },
    show() {
        if (!available) return
        StatusBar.show()
    },
}

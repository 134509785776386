import { Capacitor } from '@capacitor/core'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

export const analytics = {
    setScreenName: ({ screenName }) => {
        if (!Capacitor.isNativePlatform()) {
            return
        }
        if (screenName) {
            return FirebaseAnalytics.setScreenName({
                screenName,
            })
        }
    },
    setUserProperty: ({ name, value }) => {
        if (!Capacitor.isNativePlatform()) {
            return
        }
        return FirebaseAnalytics.setUserProperty({
            name,
            value,
        })
    },
}

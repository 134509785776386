<template>
    <div
        class="absolute w-full font-medium text-md flex items-center justify-between px-3 z-10 overflow-hidden pt-[var(--beyond-safe-area-inset-top)] border-b border-b-transparent transition-colors duration-300"
        :class="[
            headerImage ? 'bg-transparent' : 'header bg-white bg-opacity-80',
            {
                '!border-b-gray-300': hasScrolled && !headerImage,
                'use-blur': useBlur,
            },
        ]"
    >
        <div :class="{ 'w-[96px] shrink-0': isIos, 'w-[50px]': !isIos }">
            <div data-cy="navigate-back" class="w-[38px] h-[38px] flex items-center justify-center rounded-full" :class="{ 'shadow bg-white': headerImage }" @click="goBack">
                <PhIcon v-if="isIos" icon="caret-left" weight="light" :size="24" />
                <PhIcon v-else icon="arrow-left" weight="light" :size="24" />
            </div>
        </div>

        <span
            class="flex-1 truncate text-gray-900 font-medium"
            :class="{
                'text-center': isIos,
                'text-left': !isIos,
            }"
        >
            <slot name="title" />
        </span>

        <!-- teleport items here via <Teleport to="#header-menu"> -->
        <div id="header-menu" class="min-w-[50px] flex items-right justify-end space-x-1" :class="{ 'w-[96px] shrink-0': isIos }">
            <slot name="menu" />
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        headerImage: Boolean,
        hasScrolled: Boolean,
    })

    const deviceStore = useDeviceStore()
    const appStore = useAppStore()

    const { isAndroid, isIos } = storeToRefs(deviceStore)
    const { initialUrlSearch } = storeToRefs(appStore)

    const useBlur = computed(() => !initialUrlSearch.value.includes('emulate'))

    const router = useIonRouter()

    const goBack = () => {
        router.back()
    }
</script>

<style scoped>
    .header.use-blur {
        backdrop-filter: saturate(180%) blur(10px);
    }
</style>

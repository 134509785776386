import Launch from '@/views/Launch.vue'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import Module from '@/views/Module.vue'
import Maintenance from '@/views/Maintenance.vue'

export const routes = [
    {
        path: '/',
        name: 'launch',
        component: Launch,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/view/:id',
        name: 'view',
        component: Module,
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance,
    },
]

import UICardDetailText from '@/components/ui/CardDetailLayouts/UICardDetailText.vue'
import UICardDetailGallery from '@/components/ui/CardDetailLayouts/UICardDetailGallery.vue'
import UICardDetailMap from '@/components/ui/CardDetailLayouts/UICardDetailMap.vue'
import UICardDetailRow from '@/components/ui/CardDetailLayouts/UICardDetailRow.vue'
import UICardDetailCollapsible from '@/components/ui/CardDetailLayouts/UICardDetailCollapsible.vue'

export default {
    text: UICardDetailText,
    map: UICardDetailMap,
    gallery: UICardDetailGallery,
    row: UICardDetailRow,
    collapsable: UICardDetailCollapsible,
}

<template>
    <div
        class="item-list relative flex transition-colors duration-150 border-b border-gray-200"
        data-list-item="list"
        :class="{
            'space-x-3': !image,
            'items-stretch': !image && !avatar,
            'items-center': (avatar || image) && !isLargeImage,
            'space-x-4': image && !isLargeImage,
            'flex-col space-y-3': image && isLargeImage,
            'hover:bg-gray-100 active:bg-gray-100': hasLink,
            'bg-primary-700/[.07] hover:bg-primary-800/[.14] active:bg-primary-800/[.14]': active,
            'bg-primary-50': !read && readBackground,
            'bg-gray-100': expands && !collapsed,
            'm-3 rounded-md overflow-hidden border border-gray-300 mb-9 ': border,
            'pb-4': border && !isImageOnly,
            'pl-4 pr-6 py-4': !isImageOnly && !border,
            'min-h-[87px]': $slots.indicators,
        }"
        @click="handleClick"
    >
        <template v-if="image">
            <div
                class="relative"
                :class="{
                    'h-20 w-20': !isLargeImage,
                    'h-full w-full': isLargeImage && border,
                    'h-[200px] w-full': isLargeImage && !border,
                }"
            >
                <div
                    class="flex-shrink-0 relative overflow-hidden before:content-[''] before:absolute before:inset-0 before:bg-gray-200 before:animate-pulse"
                    :class="{
                        'h-20 w-20': !isLargeImage,
                        'w-full': isLargeImage,
                        'h-[200px]': isLargeImage && !isFullHeightImage,
                        'rounded-xl before:rounded-xl': !border,
                    }"
                >
                    <img
                        v-if="isFullHeightImage"
                        :src="image"
                        alt=""
                        class="max-h-[450px] w-full relative object-cover"
                    />
                    <div
                        v-else
                        :style="{ backgroundImage: `url('${image}'` }"
                        class="flex-shrink-0 bg-cover bg-center w-full h-full relative z-10"
                    />

                    <div v-if="isVideo" class="absolute inset-0 flex items-center justify-center z-20">
                        <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 0C13.957 0 0.5 13.4583 0.5 30C0.5 46.5417 13.957 60 30.5 60C47.043 60 60.5 46.5417 60.5 30C60.5 13.4583 47.043 0 30.5 0ZM42.4263 31.0511L24.9263 42.3011C24.7213 42.4341 24.4844 42.5 24.25 42.5C24.045 42.5 23.8374 42.4487 23.6519 42.3475C23.249 42.1277 23 41.7077 23 41.25V18.75C23 18.2923 23.249 17.8723 23.6519 17.6525C24.0474 17.4353 24.543 17.4486 24.9263 17.6989L42.4263 28.9489C42.7828 29.1784 43 29.5752 43 30C43 30.4248 42.7828 30.8215 42.4263 31.0511Z" fill="white" />
                        </svg>
                    </div>
                </div>

                <div
                    v-if="notification"
                    data-cy="read-indicator"
                    class="w-3 h-3 rounded-full absolute -left-1 -top-1 border border-primary-700 z-30"
                    :class="{
                        'bg-primary-700': !read,
                        'bg-white': read,
                    }"
                />
            </div>
        </template>

        <template v-else>
            <UIAvatar v-if="avatar" :avatar="avatar" />
            <div v-if="line" class="list-line w-0.5 bg-primary-700" />
            <div
                v-if="notification"
                data-cy="read-indicator"
                class="w-3 h-3 rounded-full mt-1 flex-shrink-0 border border-primary-700"
                :class="{
                    'bg-primary-700': !read,
                    'bg-white': read,
                }"
            />
        </template>
        <div
            class="space-y-2 flex-1"
            :class="{
                'pl-4 pr-6': border,
                hidden: isImageOnly,
            }"
        >
            <div class="flex">
                <div class="flex-1 space-y-2">
                    <div v-if="badges?.length" class="flex items-center space-x-1">
                        <DBadge
                            v-for="(badge, index) in badges"
                            :key="`badge-${index}`"
                            :color="badge.color"
                            :icon="badge.icon"
                            rounded
                        >
                            {{badge.text}}
                        </DBadge>
                    </div>
                    <p v-if="$slots.title || title || $slots.indicators" class="text-gray-900 font-medium flex items-center justify-between">
                        <span class="line-clamp-2"><slot name="title">{{ title }}</slot></span>
                        <span
                            v-if="$slots.indicators"
                            class="text-xs text-right whitespace-nowrap self-start"
                            :class="{
                                'text-primary-700 font-medium': active,
                                'font-normal text-gray-600': !active,
                                '-mr-12': rightIcon,
                            }">
                            <slot name="indicators" />
                        </span>
                    </p>
                    <p v-if="$slots.subheading || subheading" class="prose-em:text-gray-500 text-gray-700 text-sm font-medium line-clamp-3">
                        <slot name="subheading">{{ subheading }}</slot>
                    </p>
                </div>
                <div v-if="expands" class="flex items-center space-x-2 ml-auto">
                    <PhIcon :icon="collapsed ? 'caret-down' : 'caret-up'" class="text-gray-500" :size="24" weight="light" />
                </div>
            </div>
            <template v-if="!expands || (expands && !collapsed)">
                <p v-if="$slots.body || body" class="text-gray-700 text-sm" :class="lineClampClass">
                    <slot name="body">{{ body }}</slot>
                </p>
                <p
                    v-if="$slots.subtitle || subtitle"
                    class="prose-p:my-1 first:prose-p:mt-0 text-gray-600 text-[13px]"
                    :class="{ 'line-clamp-2': !expands }"
                >
                    <slot name="subtitle">{{ subtitle }}</slot>
                </p>
                <p
                    v-if="footerAvatar"
                    class="flex items-center"
                >
                    <UIAvatar :avatar="footerAvatar" />
                    <span class="prose-p:my-1 first:prose-p:mt-0 text-gray-600 text-[13px]">
                        {{ footerAvatar.description }}
                    </span>
                </p>
            </template>
        </div>

        <div v-if="unreadCount" class="text-sm font-medium flex items-center justify-center w-6 h-6 bg-red-700 text-white rounded-full" data-cy="group-notification">
            {{unreadCount}}
        </div>

        <div v-if="hasLink && !isLargeImage && !expands" class="flex items-center space-x-2 ml-auto" :class="{ 'mt-4': $slots.indicators }">
            <span class="text-gray-600"><slot name="right-text" /></span>
            <slot name="right-content">
                <UIIcon v-if="rightIcon" :icon="rightIcon" class="text-gray-500" :size="24" weight="regular" />
            </slot>
        </div>
    </div>
</template>

<script setup>
    import { DBadge } from '@digistorm/spark'
    import { useSlots } from 'vue'
    import UIAvatar from '@/components/ui/UIAvatar.vue'
    import UIIcon from '@/components/ui/UIIcon.vue'

    const emit = defineEmits(['click'])

    const slots = useSlots()

    const props = defineProps({
        title: String,
        body: String,
        subtitle: String,
        subheading: String,
        notification: Boolean,
        read: Boolean,
        readBackground: Boolean,
        active: Boolean,
        line: Boolean,
        lineClamp: Number,
        image: String,
        imageType: {
            type: String,
            default: 'thumbnail',
        },
        isVideo: Boolean,
        hasLink: Boolean,
        badges: Array,
        rightIcon: {
            type: Object,
            default: null,
        },
        expands: Boolean,
        border: Boolean,
        avatar: Object,
        footerAvatar: Object,
        unreadCount: Number,
    })

    const collapsed = ref(true)

    const lineClampClass = computed(() => {
        switch (props.lineClamp) {
            case 1:
                return 'line-clamp-1'
            case 2:
                return 'line-clamp-2'
            case 3:
                return 'line-clamp-3'
            case 4:
                return 'line-clamp-4'
            case 5:
                return 'line-clamp-5'
            case 6:
                return 'line-clamp-6'
            default:
                return ''
        }
    })

    const isLargeImage = computed(() => {
        return props.imageType === 'large' || props.imageType === 'full-height'
    })

    const isFullHeightImage = computed(() => {
        return props.imageType === 'full-height'
    })

    const isImageOnly = computed(() => {
        if (isEmpty(props.image)) {
            return false
        }
        return isEmpty(props.title)
            && !slots.title
            && isEmpty(props.body)
            && !slots.body
            && isEmpty(props.subtitle)
            && !slots.subtitle
            && isEmpty(props.subheading)
            && !slots.subheading
    })

    const handleClick = () => {
        if (props.expands) {
            // Toggle expand
            collapsed.value = !collapsed.value
            return
        }
        emit('click')
    }
</script>

<style scoped>
    .item-list:nth-child(2n) .list-line {
        opacity: 0.5;
    }
</style>

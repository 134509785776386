<template>
    <div>
        <ModuleComponents :modules="props.config.items" />

        <BiometricToggle />
        <MockDataOption />
    </div>
</template>

<script setup>
    import { onMounted } from 'vue'

    import BiometricToggle from '@/components/app/settings/BiometricToggle.vue'
    import MockDataOption from '@/components/app/settings/MockDataOption.vue'
    import ModuleComponents from '@/components/ModuleComponents.vue'

    const moduleHelpers = useModuleHelpers()
    const appStore = useAppStore()
    const deviceStore = useDeviceStore()

    const props = defineProps({
        config: Object,
    })

    onMounted(async () => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })
</script>

<template>
    <div class="-mx-4">
        <div class="flex items-center space-x-2 p-1 active:bg-secondary" @click="collapsed = !collapsed">
            <div class="w-11 h-11 flex items-center justify-center">
                <PhIcon :icon="collapsed ? 'caret-right' : 'caret-down'" weight="regular" />
            </div>
            <span class="text-gray-800 text-sm font-medium">{{item.title}}</span>
        </div>
        <div class="px-4" v-if="!collapsed">
            <component
                v-for="(childItem, index) in item.items"
                :key="index"
                :is="cardDetailTypes[childItem.type]"
                :item="childItem"
                @click="emit('click', $event)"
            />
        </div>
    </div>
</template>

<script setup>
    import cardDetailTypes from '@/constants/cardDetailTypes'

    const emit = defineEmits(['click'])

    const props = defineProps({
        item: Object,
    })

    const collapsed = ref(props.item.collapsed ?? true)
</script>

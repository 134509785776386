import { defineStore } from 'pinia'
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'
import { Capacitor } from '@capacitor/core'

const { width } = useWindowSize()

export const useDeviceStore = defineStore('device', {
    state: () => {
        return {
            device: null,
            forceTabletMode: false,
        }
    },
    getters: {
        platform() {
            return this.device.platform === 'web' ? this.device.operatingSystem : this.device.platform
        },
        isAndroid() {
            return this.platform === 'android'
        },
        isIos() {
            return this.platform === 'ios'
        },
        isWeb() {
            return this.device.platform === 'web'
        },
        isTablet() {
            return this.forceTabletMode || width.value >= 744 // iPad Mini portrait width
        },
    },
    actions: {
        async timezone() {
            try {
                return Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch (e) {
                return null
            }
        },
        async locale() {
            return Device.getLanguageCode()
                .then((code) => {
                    return code.value
                })
        },
        async load() {
            this.device = {
                ...await Device.getId(),
                ...await Device.getInfo(),
            }

            return this.device
        },

    },
})

<template>
    <div class="active:brightness-90 hover:brightness-90 px-4 py-6" @click="emit('click')">
        <div class="flex items-center">
            <p class="font-medium text-gray-900 flex-1 truncate">
                <slot name="title">{{title}}</slot>
            </p>
            <span class="text-gray-600 pl-1">
                <slot name="indicators" />
            </span>
        </div>

        <div class="w-full h-[200px] bg-cover bg-center mt-2 rounded-xl" :style="{ backgroundImage: `url(${imageUrl})` }" />
    </div>
</template>

<script setup>
    const emit = defineEmits(['click'])

    const props = defineProps({
        title: String,
        imageUrl: String,
    })

</script>

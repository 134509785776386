<template>
    <div
        class="relative"
        :style="{
            marginTop: `-${upwardsOverlap / 16}rem`,
            marginBottom: `-${downwardsOverlap / 16}rem`,
        }"
        :class="gridBorderCollapseClasses"
    >
        <div
            class="grid grid-flow-row auto-columns-auto"
            data-cy="dashboard-button-group"
            :class="[paddingClass, gridColumnClass]"
            :style="{
                gridGap: `${buttonSpacing / 16}rem`,
            }"
        >
            <Button
                ref="buttonElements"
                v-for="(button, index) in buttons"
                :button="button"
                :key="button.id"
                :button-group="item"
                :overlay="overlay"
                :class="[buttonColumnClasses, buttonBorderCollapseClasses]"
                :style="[firstRowStyle(index)]"
            />
        </div>
    </div>
</template>

<script setup>
    import Button from '@/components/dashboard/components/ButtonGroup/Button.vue'

    const props = defineProps({
        item: Object,
        index: Number,
        overlay: Boolean,
    })

    const deviceStore = useDeviceStore()

    const { isTablet } = storeToRefs(deviceStore)

    const buttonElements = ref([])

    const buttons = computed(() => {
        return get(props.item, 'items', [])
    })

    const alignment = computed(() => get(props.item.data, 'alignment'))
    const padding = computed(() => get(props.item.data, 'padding'))

    const buttonSpacing = computed(() => get(props.item.data, 'button.button_spacing'))
    const buttonAddBorder = computed(() => get(props.item.data, 'button.add_border'))
    const buttonIconStyle = computed(() => get(props.item.data, 'button.icon_style'))
    const upwardsOverlap = computed(() => get(props.item.data, 'upwards_overlap'))
    const downwardsOverlap = computed(() => get(props.item.data, 'downwards_overlap'))

    const columns = computed(() => {
        let cols = get(props.item.data, 'columns', 3)

        if (isTablet.value) {
            cols = get(props.item.data, 'tablet_columns', 5)
        }

        if (isNil(cols)) {
            cols = 3
        }
        return cols
    })

    const gridColumnClass = computed(() => {
        if (columns.value === 5) {
            return 'grid-cols-10'
        }
        return 'grid-cols-12'
    })

    const gridColumnTotal = computed(() => {
        if (columns.value === 5) {
            return 10
        }
        return 12
    })

    const paddingClass = computed(() => {
        switch (padding.value) {
            case 'sm':
                return 'p-2'
            case 'md':
                return 'p-4'
            case 'lg':
                return 'p-6'
            case 'none':
            default:
                return ''
        }
    })

    const buttonColumnClasses = computed(() => {
        switch (columns.value) {
            case 1:
                return 'col-span-12'
            case 2:
                return 'col-span-6'
            case 3:
                return 'col-span-4'
            case 4:
                return 'col-span-3'
            case 5:
                return 'col-span-2'
            default:
                return 'col-span-4'
        }
    })

    const collapseBorder = computed(() => {
        if (buttonSpacing.value || !buttonAddBorder.value) {
            return false
        }
        return true
    })

    const gridBorderCollapseClasses = computed(() => {
        if (!collapseBorder.value) {
            return ''
        }
        return buttonIconStyle.value === 'thin'
            ? 'p-[0.5px]'
            : 'p-px'
    })

    const buttonBorderCollapseClasses = computed(() => {
        if (!collapseBorder.value) {
            return ''
        }
        return buttonIconStyle.value === 'thin'
            ? 'm-[-0.5px]'
            : '-m-px'
    })

    // adjusts first row accordingly to the alignment setting
    const firstRowStyle = (index) => {
        const totalButtons = buttons.value.length

        // nothing to do if the number of visible buttons is divisible by the number of columns
        const remainingButtons = totalButtons % columns.value
        if (remainingButtons === 0) {
            return
        }

        if (alignment.value === 'stretch') {
            // only stretch the first offset index buttons
            if (index >= remainingButtons) {
                return
            }
            return {
                gridColumn: `span ${gridColumnTotal.value / remainingButtons} / span ${gridColumnTotal.value / remainingButtons}`,
            }
        }

        // we only care about the last row (number of buttons - columns)
        if (index < totalButtons - remainingButtons) {
            return
        }

        let colStart = null
        if (alignment.value === 'center') {
            colStart = 1 + ((gridColumnTotal.value / columns.value) * (columns.value - remainingButtons)) / 2
        }

        if (alignment.value === 'right') {
            colStart = 13 - Math.floor(((gridColumnTotal.value / columns.value) * remainingButtons))
        }

        let transform = null
        if (colStart % 1 > 0) {
            colStart -= 1
            transform = `translateX(calc(50% + ${buttonSpacing.value / 2}px))`
        }

        let columnIndex = index
        if (totalButtons - remainingButtons > 0) {
            columnIndex = index % (totalButtons - remainingButtons)
        }
        if (columnIndex > 0 || alignment.value === 'left') {
            if (transform) {
                return {
                    transform,
                }
            }
            return
        }
        return {
            gridColumnStart: Math.floor(colStart),
            transform,
        }
    }
</script>

<template>
    <UISimple v-if="biometry?.isAvailable" @click="toggleBiometry">
        <template v-slot:title>{{ biometryType }}</template>

        <template v-slot:right-content>
            <DSwitch :model-value="isEnabled" class="pointer-events-none" />
        </template>
    </UISimple>
    <!-- Dummy toggle for designers to see layout on web -->
    <UISimple v-else-if="isWeb" :class="{ 'pointer-events-none': isWeb }">
        <template v-slot:title><span class="opacity-30">Face ID</span></template>
        <template v-slot:right-content>
            <DSwitch :model-value="true" class="opacity-30" />
        </template>
    </UISimple>
</template>

<script setup>
    import { storeToRefs } from 'pinia'
    import { DSwitch } from '@digistorm/spark'

    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'
    import { biometric } from '@/services/biometric'

    const deviceStore = useDeviceStore()

    const { isWeb } = storeToRefs(deviceStore)

    const isEnabled = ref(false)
    const biometry = ref(null)

    const biometryType = computed(() => {
        return biometric.biometryTypeFromId(biometry.value.biometryType)
    })

    const authenticateBiometrics = async () => {
        try {
            await biometric.authenticate().then(() => {
                isEnabled.value = true
                biometric.setEnabled(true)
            }).catch(() => {
                isEnabled.value = false
                biometric.setEnabled(false)
            })
        } catch (error) {
            // fail, reset
            isEnabled.value = false
            await biometric.setEnabled(false)
        }
    }

    const toggleBiometry = async () => {
        if (isEnabled.value) {
            await biometric.setEnabled(false)
            isEnabled.value = false
        } else {
            await authenticateBiometrics()
        }
    }

    onBeforeMount(async () => {
        const biometryEnabled = await biometric.isEnabled()
        isEnabled.value = !!biometryEnabled
        biometry.value = await biometric.biometricDetails()
    })

</script>

<template>
    <div class="px-4 py-6">
        <div v-if="config.data && config.data.title" class="text-lg font-medium text-gray-900 mb-2">
            {{config.data.title}}
        </div>

        <div class="grid grid-cols-3 gap-[3px] -mx-4 mb-12">
            <div
                v-for="(image, index) in config.data.images"
                :key="index"
                @click="openGallery(index)"
                @keydown.enter="openGallery(index)"
                class="w-full min-h-[130px] bg-cover bg-center bg-no-repeat"
                :style="{ backgroundImage: `url(${getImage(image)})` }"
                data-cy="gallery-image"
            />
        </div>
    </div>
</template>

<script setup>

    const props = defineProps({
        config: Object,
    })

    const moduleStore = useModuleStore()
    const router = useIonRouter()

    const moduleHelpers = useModuleHelpers()
    const { gallery } = useGallery()

    const getImage = (image) => {
        if (image.thumbnail) {
            return image.thumbnail
        }

        return image.image
    }

    const openGallery = (index) => {
        gallery({
            images: get(props.config, 'data.images'),
            start: index,
        })
    }
</script>

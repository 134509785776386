import { defineStore } from 'pinia'

import { api, mock } from '@/services'

export const useDashboardStore = defineStore('dashboard', {
    state: () => {
        return {
            loaded: false,
            screen: 'homepage',
            dashboard: {},
        }
    },
    actions: {
        // Initial loading of the dashboard
        async load() {
            const { appId } = useAppStore()

            // If mocking use the mock dashboard
            if (mock.mocking()) {
                this.dashboard = mock.dashboard()
                this.loaded = true
                return
            }

            return api.get(`/${appId}/dashboards`)
                .then(({ data }) => {
                    this.dashboard = data
                    this.loaded = true
                })
        },

        setScreen(screen) {
            this.screen = screen
        },
    },
})

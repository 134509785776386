import dayjs from 'dayjs'
import dayjsUtc from 'dayjs/plugin/utc'
import dayjslocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat'
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat'
import dayjsObjectSupport from 'dayjs/plugin/objectSupport'
import dayjsMinMax from 'dayjs/plugin/minMax'
import dayjsIsBetween from 'dayjs/plugin/isBetween'
import dayjsDuration from 'dayjs/plugin/duration'
import dayjsLocaleData from 'dayjs/plugin/localeData'
import dayjsRelativeTime from 'dayjs/plugin/relativeTime'
import dayjsIsoWeek from 'dayjs/plugin/isoWeek'
import dayjsIsToday from 'dayjs/plugin/isToday'

import './locales'

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsAdvancedFormat)
dayjs.extend(dayjslocalizedFormat)
dayjs.extend(dayjsIsSameOrBefore)
dayjs.extend(dayjsIsSameOrAfter)
dayjs.extend(dayjsTimezone)
dayjs.extend(dayjsCustomParseFormat)
dayjs.extend(dayjsMinMax)
dayjs.extend(dayjsDuration)
dayjs.extend(dayjsLocaleData)
dayjs.extend(dayjsRelativeTime)
dayjs.extend(dayjsObjectSupport)
dayjs.extend(dayjsIsBetween)
dayjs.extend(dayjsIsoWeek)
dayjs.extend(dayjsIsToday)

dayjs.tz.setDefault('Australia/Brisbane')

// default locale
dayjs.locale('en-au')

window.dayjs = dayjs

export default dayjs

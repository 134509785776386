<template>
    <!-- Dummy toggle for designers to see layout on web -->
    <UISimple v-if="faking" :class="{ 'pointer-events-none': faking }">
        <template v-slot:title><span class="opacity-30">{{ title }}</span></template>
        <template v-slot:right-content>
            <DSwitch :model-value="true" class="opacity-30" />
        </template>
    </UISimple>
    <UISimple v-else-if="pushNotifications.available" @click="toggleNotifications">
        <template v-slot:title>{{ title }}</template>
        <template v-slot:right-content>
            <DSwitch :model-value="enabled" class="pointer-events-none" />
        </template>
    </UISimple>
</template>

<script setup>
    import { DSwitch } from '@digistorm/spark'
    import { onBeforeUnmount } from 'vue'
    import { storeToRefs } from 'pinia'
    import { PushNotifications } from '@capacitor/push-notifications'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'

    const props = defineProps({
        config: Object,
        title: {
            type: String,
            default: 'Notifications',
        },
    })

    const emit = defineEmits(['toggle'])

    const appStore = useAppStore()
    const deviceStore = useDeviceStore()
    const pushNotifications = usePushNotifications()

    const { mockData } = storeToRefs(appStore)
    const { isWeb } = storeToRefs(deviceStore)
    const enabled = ref(true)

    const faking = computed(() => {
        return isWeb.value || mockData.value
    })

    const toggleNotifications = async () => {
        if (enabled.value) {
            await pushNotifications.disable()
        } else {
            await pushNotifications.enable()
        }

        enabled.value = !enabled.value

        const perms = await PushNotifications.checkPermissions()
        console.log('current perms:', perms)

        emit('toggle', enabled.value)
    }

    const handleApiRegistrationError = () => {
        enabled.value = false
        emit('toggle', enabled.value)
    }

    onBeforeMount(async () => {
        if (!pushNotifications.available) {
            return
        }
        enabled.value = await pushNotifications.enabled()

        pushNotifications.events.on('apiRegistrationError', handleApiRegistrationError)

        const perms = await PushNotifications.checkPermissions()
        console.log('current perms:', perms)
    })

    onBeforeUnmount(() => {
        pushNotifications.events.off('apiRegistrationError', handleApiRegistrationError)
    })
</script>

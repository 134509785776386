<template>
    <UICard
        :title="title"
        :badge="badge"
        :header="header"
        class="relative"
    >
        <div class="-mb-3.5">
            <component
                v-for="(item, index) in items"
                :key="index"
                :is="cardDetailTypes[item.type]"
                :item="item"
                @click="emit('click', $event)"
            />
        </div>

        <div v-if="$slots.default" class="pt-3.5">
            <slot />
        </div>

        <div :class="{ 'opacity-0': !loading }" class="transition-opacity pointer-events-none absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white/80">
            <UILoading class="w-8 h-8" />
        </div>
    </UICard>
</template>

<script setup>
    import cardDetailTypes from '@/constants/cardDetailTypes'
    import UICard from './UICard.vue'
    import UILoading from '@/components/ui/UILoading.vue'

    const emit = defineEmits(['click'])

    const props = defineProps({
        title: String,
        badge: Object,
        header: Object,
        details: {
            type: Array,
            default: () => [],
        },
        loading: Boolean,
    })

    const items = computed(() => {
        return props.details
    })
</script>

<template>
    <div
        @click="onClick"
        class="-mx-4 px-4 pb-3.5 transition-colors duration-150"
        :class="{ 'hover:bg-gray-100 active:bg-gray-100': !!item.link }"
        data-cy="card-detail-map"
    >
        <UICardDetailText :item="textItem" class="!bg-transparent" @click="onClick" :data-cy="false" />
        <img v-if="item.image_url" :src="item.image_url" :alt="item.address" class="w-full rounded pointer-events-none" />
    </div>
</template>

<script setup>
    import { escape } from 'lodash'
    import UICardDetailText from '@/components/ui/CardDetailLayouts/UICardDetailText.vue'

    const props = defineProps({
        item: Object,
    })

    const moduleStore = useModuleStore()

    const moduleHelpers = useModuleHelpers()

    const clicking = ref(false)
    const textItem = ref({
        title: props.item.title,
        body: escape(props.item.address),
        right_icon: 'map-pin',
        link: props.item.link,
    })

    const onClick = () => {
        if (clicking.value) {
            return
        }

        clicking.value = true
        moduleHelpers.handleClick(props.item)
            .finally(() => {
                clicking.value = false
            })
    }
</script>

import { v4 as uuidv4 } from 'uuid'

export const useGallery = createGlobalState(
    () => {
        const activeGallery = ref({})

        const gallery = (options) => {
            activeGallery.value = options
        }

        const close = (id) => {
            activeGallery.value = {}
        }

        return {
            activeGallery,
            gallery,
            close,
        }
    },
)

<template>
    <Icon
        :icon="`ph:${icon}${weightText}`"
        data-cy="icon"
        :style="{
            width: `${size}px`,
            height: `${size}px`,
            color,
        }"
    />
</template>

<script setup>
    import { enableCache, Icon } from '@iconify/vue'

    const props = defineProps({
        icon: String,
        size: {
            type: Number,
            default: 20,
            description: 'The size of the icon',
        },
        weight: {
            type: String,
            default: 'fill',
        },
        color: {
            type: String,
            default: null,
        },
    })

    const weightText = computed(() => {
        return props.weight === 'regular' ? '' : `-${props.weight}`
    })

    enableCache('local')

</script>

import { registerPlugin, WebPlugin } from '@capacitor/core'

class PluginApi extends WebPlugin {
    getEnvironment() {
        // Note: There doesn't appear to be a way to get the config from a WebPlugin
        throw new Error('Web Platform not supported')
    }
}

const AppIdentity = registerPlugin(
    'AppIdentity',
    {
        web: () => new PluginApi(),
    },
)

export { AppIdentity }
